import { DropdownMenuOptionType } from '@/components/molecules/DropdownMenu';
import fuzzysort from 'fuzzysort';
import stringSimilarity from 'string-similarity';

const searchList = (list: string[], input: string) => {
  const results = fuzzysort.go(input, list, {
    limit: 5,
    threshold: -10000,
  });
  return Array.from(new Set(results.map((result) => result.target)));
};

export const fuzzySearch = (
  newInput: string,
  makesList: string[],
  modelsList: { [key: string]: string[] }
) => {
  // Split input into words
  const inputList = newInput.split(' ');

  // Get model-to-make mapping
  const modelKeys = Object.keys(modelsList).reduce(
    (acc, make) => {
      modelsList[make].forEach((model) => {
        acc[model] = make;
      });
      return acc;
    },
    {} as { [key: string]: string }
  );

  const modelListFlat = Object.values(modelsList).flat();

  // Get suggested makes from full input and individual words
  const suggestedMakes = [
    ...searchList(makesList, newInput),
    ...inputList
      .filter((word) => word.length > 1)
      .flatMap((word) => searchList(makesList, word)),
  ];

  const uniqueSuggestedMakes = Array.from(new Set(suggestedMakes));

  // Get models from suggested makes
  const suggestedModelsFromMakes = uniqueSuggestedMakes.reduce(
    (acc, make) => {
      if (modelsList[make]) {
        acc[make] = modelsList[make];
      }
      return acc;
    },
    {} as { [key: string]: string[] }
  );

  // Filter out makes from input and re-search remaining input for models
  const filteredInput = inputList
    .filter(
      (word) =>
        !uniqueSuggestedMakes
          .map((make) => make.toLowerCase())
          .includes(word.toLowerCase())
    )
    .join(' ');

  const suggestedModels = searchList(modelListFlat, filteredInput);

  // Build final list - models take priority as they are more specific
  const finalList = suggestedModels.reduce(
    (acc, model) => {
      const make = modelKeys[model];
      if (!acc[make]) {
        acc[make] = [];
      }
      acc[make].push(model);
      return acc;
    },
    {} as { [key: string]: string[] }
  );

  uniqueSuggestedMakes.forEach((make) => {
    if (!finalList[make]) {
      finalList[make] = [];
    }
    finalList[make].push(make);
    (suggestedModelsFromMakes[make] || []).forEach((model) => {
      if (suggestedModels.includes(model)) {
        finalList[make].push(model);
      }
    });
  });

  // Convert to dropdown menu format and remove duplicates
  const uniqueItems = new Set<string>();
  let finalMenuItems: DropdownMenuOptionType[] = [];

  Object.keys(finalList).forEach((make) => {
    finalList[make].forEach((item) => {
      const text = make === item ? item : `${make} ${item}`;
      const value = make === item ? item : `${make} ${item}`;
      const key = `${text}-${value}`;
      const makeIcon = make === item ? { make } : undefined;

      if (!uniqueItems.has(key)) {
        uniqueItems.add(key);
        finalMenuItems.push({ text, value, makeIcon });
      }
    });
  });

  const itemsWithScores = finalMenuItems.map((item) => ({
    ...item,
    score: stringSimilarity.compareTwoStrings(newInput, item.text),
  }));

  const sortedItems = itemsWithScores.sort((a, b) => b.score - a.score);
  finalMenuItems = sortedItems.map(({ score, ...item }) => item);
  return finalMenuItems;
};

export const stringMatch = (
  newInput: string,
  makesList: string[],
  modelsList: { [key: string]: string[] }
) => {
  const inputMake = newInput.split(' ')[0];

  // Get model-to-make mapping
  const modelKeys = Object.keys(modelsList).reduce(
    (acc, make) => {
      modelsList[make].forEach((model) => {
        acc[model] = make;
      });
      return acc;
    },
    {} as { [key: string]: string }
  );

  // flat list of models
  const modelListFlat = Object.values(modelsList).flat();

  // string match all makes and models
  const stringMatchAllWithScores = [...makesList, ...modelListFlat].reduce(
    (acc, item) => {
      if (item) {
        const score = stringSimilarity.compareTwoStrings(
          newInput.toLowerCase(),
          item.toLowerCase()
        );
        if (score > 0) {
          acc[item] = score;
        }
      }
      return acc;
    },
    {} as { [key: string]: number }
  );

  // sort by score and take top 10
  const orderedStringMatches = Object.keys(stringMatchAllWithScores)
    .sort((a, b) => stringMatchAllWithScores[b] - stringMatchAllWithScores[a])
    .slice(0, 10);

  // if no matches, return empty array
  if (orderedStringMatches.length === 0) {
    return [];
  }

  // sort the matches alphabetically depending on the input
  const sortedOptions = orderedStringMatches
    .filter((item) => item.toLowerCase().startsWith(inputMake))
    .sort((a, b) => {
      const aLower = a.toLowerCase();
      const bLower = b.toLowerCase();
      return aLower.localeCompare(bLower);
    });

  if (sortedOptions.length === 0) {
    return [];
  }
  // get top match and make
  const topMatch = sortedOptions[0];
  const topMake =
    modelKeys[topMatch] || makesList.find((make) => make === topMatch);

  // refined matches are based on the make of the top match
  // first item is the top match
  // next items are items in orderedStringMatches that have the same make as the top match
  // if top make is not in the list, add it
  // if there are less than 5 items, add more models from the top make
  const refinedMatches = [
    topMatch,
    ...orderedStringMatches.filter(
      (match, index) => index > 0 && modelKeys[match] === topMake
    ),
  ];

  if (topMake) {
    if (topMatch !== topMake) {
      refinedMatches.push(topMake);
    }
    if (refinedMatches.length < 5) {
      const makeModels = modelsList[topMake].filter(
        (model) => !refinedMatches.includes(model)
      );
      const diff = 5 - refinedMatches.length;
      refinedMatches.push(...makeModels.slice(0, diff));
    }
  }

  // build final menu items
  const finalMenuItems = refinedMatches.map((item) => {
    const isMake = makesList.includes(item);
    const text = isMake ? item : `${modelKeys[item]} ${item}`;
    const makeIcon = isMake ? { make: item } : undefined;

    return {
      text,
      value: text,
      makeIcon,
    };
  });

  return finalMenuItems;
};
