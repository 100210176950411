import React from 'react';
import { Component } from '@/types/interfaces';

export interface GridProps extends Component {
  children: React.ReactNode;
}

const Grid: React.FC<GridProps> = ({ className, children, ...props }) => (
  <article
    className={`grid grid-cols-4 gap-x-5 m:grid-cols-8 l:grid-cols-12 ${className}`}
    {...props}
  >
    {children}
  </article>
);

export default Grid;
